<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <MatrizLista
            idTipoOpex="3"
            tipoOpex="gadm"
            nombreTipoOpex="Gasto Administrativo"
            vAuthAcl="gh-pres-opex-ga"
            v-auth-acl="'gh-pres-opex-ga'"
          ></MatrizLista>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OpexGastoAdm',
  components: {
    MatrizLista: () => import('../../components/MatrizLista')
  },
  data: () => ({
    page: {
      title: 'OPEX Gasto Administrativo'
    },
    breadcrumbs: [
      {
        text: 'Presupuesto',
        disabled: true
      },
      {
        text: 'OPEX GastoAdministrativo',
        disabled: false
      }
    ]
  }),
  methods: {
    open() {},
    close() {}
  }
};
</script>
